// ----------------------------------------------------------------------

export const title = [
  'Strand 1',
  'Strand 2',
  'Strand 3',
  "Event Doesn't Have To Be Hard. Read These 7 Tips",
  'Event Is Your Worst Enemy. 7 Ways To Defeat It',
  'Event On A Budget: 7 Tips From The Great Depression',
  'Knowing These 7 Secrets Will Make Your Event Look Amazing',
  'Master The Art Of Event With These 7 Tips',
  'My Life, My Job, My Career: How 7 Simple Event Helped Me Succeed',
  'Take Advantage Of Event - Read These 7 Tips',
  'The Next 7 Things You Should Do For Event Success',
  'The Time Is Running Out! Think About These 7 Ways To Change Your Event',
  'The 7 Best Things About Event',
  'The 7 Biggest Event Mistakes You Can Easily Avoid',
  'The 7 Most Successful Event Companies In Region',
  'Think Your Event Is Safe? 7 Ways You Can Lose It Today',
  "Thinking About Event? 7 Reasons Why It's Time To Stop!",
  '7 Places To Get Deals On Event',
  'Best Event Android Apps',
  'Best Event Tips You Will Read This Year',
  'Best 30 Tips For Event',
  'Should Fixing Event Take 30 Steps?',
  'The A - Z Of Event',
  'The Next 30 Things To Immediately Do About Event',
  'The Ultimate Guide To Event',
  'Top 30 Funny Event Quotes',
  'Top 30 Quotes On Event',
  'Top 7 Lessons About Event To Learn Before You Hit 30',
  'Top 7 Ways To Buy A Used Event',
  '30 Best Ways To Sell Event',
  '30 Ideas For Event',
  '30 Lessons About Event You Need To Learn Before You Hit 40',
  '30 Methods Of Event Domination',
  '30 Things To Do Immediately About Event',
  '30 Tips To Grow Your Event',
  '30 Ways To Avoid Event Burnout',
  '30 Ways To Improve Event',
  'How To Make More Event By Doing Less',
];

export const sentence = [
  'First time delegates will be automatically placed in this Strand. Here, you will be introduced to a model, which will help you understand the importance of considering the content and context of a passage, to arrive at the “Big Idea”, which will then shape the application of the passage. <br/><br/>As part of the training process, you will have the opportunity to write a Bible study on a selected passage New Testament. On the last day of NextGen, you will have the opportunity to present your study to the rest of your strand group.',
  'Delegates must have completed Strand 1 in the previous years before they are placed in this Strand. Here, you will be taught Biblical theology and how to understand the Old Testament in light of Jesus thus, shaping the application of the passage. <br/><br/> Like Strand 1, you will have the opportunity to write a Bible study on a selected Old Testament passage. On the last day of NextGen, you will have the opportunity to present your study to the rest of your strand group.',
  'Delegates must have completed Strand 1 and Strand 2 in the previous years before they are placed in this Strand. You will be taught how to think about topics (e.g. Social Justice) in a gospel-centred manner. Develop your skills in systematic theology so that you can effectively pull together what the whole Bible has to say about particular topic.<br/><br/> Like Strand 1 and 2, you will have the opportunity to write a Bible study on this selected topic. On the last day of NextGen, you will have the opportunity to present your findings to the rest of your strand group.',
  'Error ut sit vel molestias velit.',
  'Quo quia sit nihil nemo doloremque et.',
  'Autem doloribus harum vero laborum.',
  'Tempora officiis consequuntur architecto nostrum autem nam adipisci.',
  'Voluptas sunt magni adipisci praesentium saepe.',
  'Ea architecto quas voluptates voluptas earum illo est vel rem.',
  'Ipsum expedita reiciendis ut.',
  'Architecto vel voluptatibus alias a aut non maxime ipsa voluptates.',
  'Reiciendis enim officiis cupiditate eaque distinctio laudantium modi similique consequatur.',
  'Ab autem consequatur itaque mollitia ipsum cupiditate error repudiandae nobis.',
  'Distinctio architecto debitis eligendi consequatur unde id modi accusantium possimus.',
  'At ut voluptate accusantium.',
  'Repudiandae ut qui veritatis sint.',
  'Laboriosam blanditiis quo sed et qui esse ipsam necessitatibus sed.',
  'Et molestiae et excepturi maxime omnis.',
  'Sint dolorem quam eum magnam.',
  'Rerum ut iusto iste quam voluptatem necessitatibus.',
  'Et quam in.',
  'Fugit esse tenetur.',
  'Dolorem dolor porro nihil cupiditate molestiae deserunt ut.',
  'Omnis beatae eos eius aut molestias laboriosam laborum et optio.',
  'Ut veniam quam assumenda ut voluptatibus ducimus accusamus.',
  'Quos dignissimos neque omnis reiciendis voluptatem ducimus.',
  'Laboriosam quia ut esse.',
  'Sit reiciendis nihil qui molestias et.',
  'Facilis cupiditate minima ratione quaerat omnis velit non ex totam.',
  'Provident sint esse voluptatem voluptas eveniet est.',
  'Molestias consequatur ea facilis.',
  'Tempora voluptatibus autem ut ut porro quae delectus dolorum.',
  'Et consequatur amet nemo ducimus voluptatem placeat voluptas.',
  'Modi iste atque hic voluptas sit quis deleniti quas consequatur.',
  'Omnis est aliquid odio mollitia aliquid ex.',
  'Officia possimus veniam quod molestias.',
  'Mollitia inventore recusandae provident aut.',
  'Numquam ullam beatae possimus.',
  'Fuga velit cupiditate ex culpa odio aut ut.',
  'Vero corrupti nam voluptatum excepturi est et.',
];

export const subtitle = ['New Testament Exegesis', 'Old Testament Exegesis', 'Systematic Theology'];

export const header = [
  '1. What is KVBC Trust and KVBC NextGen?',
  '2. How will KVBC NextGen 2025 be organised?',
  '3. How will it be different from previous online NextGen conferences?',
  '4. What do I need to pack for the conference?',
  '5. What does my conference payment cover?',
  '6. Can I still register if I can only make it on certain days or hours of the conference?',
  '7. How do we get to the conference venue? Is transportation provided?',
  '8. Is my fee refundable if I cannot make it for the conference?',
  '9. Can I apply for conference sponsorship? Is there an eligibility criteria?',
  '10. Is the conference limited to delegates within a certain age group?',
  '11. What are KVBC Trust and KVBC NextGen’s theological beliefs?',
];

export const description = [
  '<b>KVBC Trust</b> is a Malaysian parachurch organisation established since 1997 that organises Bible conferences and ministry platforms characterised by a robust commitment to expound the whole Bible in the light of the gospel of Jesus Christ. <br/><br/> <b>KVBC NextGen</b> is a ministry within KVBC Trust that focuses on training and discipling the next generation of Christian leaders in personal godliness and faithful Bible handling. We organise an annual conference that exemplifies this mission through talks, small group training sessions, workshops, and fellowship with others who are on this journey.',
  'Since 2024, we have brought NextGen back to its original format - 4 days away at a campsite in Malaysia, where we will meet each other face to face to sing, share, learn, and have some fun. We will not be providing online streaming for any of our sessions.',
  'During the COVID-19 pandemic, you would have attended NextGen at home, with the entire conference held over Zoom and YouTube. Since 2024, we will have all those talks, workshops, and group sessions on-site at the conference venue. For the most part, the agenda will be nearly identical and you will have even more time to spend with your strand group and friends!',
  '• Bible (Non-digital; the speakers will be preaching from the ESV this year.) \n • Notebook and stationery \n • Clothes, footwear, and toiletries for 4 days \n • Water bottle \n • Medication \n • <i>Extras:</i> Bible study material, clothes for a short hill or beach walk, mosquito repellent, snacks, money to buy books \n • <i>Not required:</i> Laptop, clothes for extreme games.',
  'Registration fees cover the costs of running the conference. We are part of the KVBC Trust, a non-profit parachurch organisation and operate under its supervision and guidance. Read more  <a href="https://kvbcequip.org">here.</a> ',
  'To get the most out of the conference, <b><u>we strongly encourage all delegates to commit to the whole duration.</b></u> Each strand group session builds from the last one, and Bible talk speakers model the NextGen model you will be learning during strand group sessions. \n\n If you can only commit to part of the conference or have any questions about this, please <a href="/contact-us/"> contact us</a>.',
  'We do not provide transport to the venue, and this is not part of your registration fee. However, we do organise ‘matchmaking’ services, where you can apply for assistance or volunteer to be a driver in the registration form. We encourage passengers to be considerate of potential cargo space and timing restrictions of drivers, and ensure an efficient arrangement.',
  'Conference fees are non-refundable. However, they are transferable! Please <a href="/contact-us/"> contact us</a> as soon as you can confirm you can no longer make it to the conference, and submit details of your replacement delegate, if you already have one arranged to take your place.',
  'KVBC NextGen encourages delegates who are seeking sponsorship to seek this out from your local church or parachurch organisation.  Please <a href="/contact-us/">contact us</a> if you need further assistance on this matter',
  "KVBC NextGen is primarily targeted at those aged between 18-35, especially those active in small group, campus and youth/kids ministry. Having said that, if you fall outside that age group, please don't hesitate to get in touch with us to see if KVBC NextGen can still be of benefit to you! There are also other conferences run by KVBC which we can direct you to for further equipping.",
  'KVBC NextGen is a ministry of KVBC Trust. You may check out KVBC Trust <a href="https://kvbcequip.org/what-we-believe/">core beliefs here.</a>',
  'To get the most out of the conference, we strongly encourage all delegates to commit to the whole duration. Each strand group session builds from the last one, and Bible talk speakers model the NextGen model you will be learning during strand group sessions. \n\n If you can only commit to part of the conference or have any questions about this, please <a href="/contact-us/"> contact us</a>.',
  'Conference fees are non-refundable. However, they are transferable. Please <a href="/contact-us/"> contact us</a> as soon as you know you won’t be able to make it, and submit details of your replacement (if possible).',
  'As KVBC NextGen seeks to support and equip church leaders, we encourage delegates who are seeking sponsorship to first seek this out from your local church. This encourages a two way partnership for the gospel. You may also <a href="/contact-us/"> contact us</a> to speak further.',
  'Although our training is targeted for young leaders in campus, youth and kids ministry, we welcome anyone who wants to be further equipped, to join us.',
];
