// @mui
import { Typography, Box, Paper, Container, Grid } from '@mui/material';
// components
import Image from '../../components/Image';
import { MotionInView, varFade } from '../../components/animate';
import { FONT_SECONDARY } from 'src/theme/typography';

// ----------------------------------------------------------------------

const CATEGORIES = [
  {
    label: 'Ivan Chow',
    icon: 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1740494126/NextGen/2025/Ivan_Chow_rffkpy.webp',
    href: '#',
    no: '1',
    description: (
      <span>
      Ivan serves as Senior Pastor and Elder at Bethesda Church Bukit Arang, a church committed to growing gospel communities in Singapore and beyond through the clear & faithful proclamation of the gospel.
      <br/><br/>
      He trained as an engineer at Imperial College London (BEng) and Stanford University (MSc) before working to develop business in the ports industry. He trained for Christian ministry at Moore Theological College (BDiv). He is married to Agnes and they have eight children.
      </span>
    ),
  },
  {
    label: 'Greg Ginn',
    icon: 'https://res.cloudinary.com/dscrdg0ou/image/upload/v1740494126/NextGen/2025/Greg_Ginn_wjigjb.webp',
    href: '#',
    no: '2',
    description: (
      <span>
        Pastor Greg serves at the International Church at Mont Kiara. Prior to arriving in Kuala Lumpur, he pastored in the U.S. for nine years. He is passionate about the Christ-centered exposition of Scripture and loves equipping others to grow in their ability to handle God's Word. 
        <br/>  <br/>He is married and has three children ranging from ages 7 to 13-years-old. He enjoys cooking, trying new foods, traveling, and spending time with his family.

        <br />
        {/* Vernon serves as the Pastor of the 4:30PM Congregation at Zion Bishan Bible-Presbyterian
        Church in Singapore. He is especially passionate about equipping Christians to disciple
        others through God’s Word. He himself has benefited from the same kind of equipping through
        various ministries in Perth, Singapore, Sydney and London – including Moore Theological
        College (Sydney) and St Helen’s Bishopsgate (London). He is the husband of one wife, Ruth,
        and is the father of Alyssa, Thaddeus & Ebenezzer (who went Home to the Lord in October
        2022).
        <br /> <br />
        Besides talking about the Good News of Jesus, his passions include good stories in any form,
        serving in the Singapore Army and jumping out of airplanes. Both Vernon and Ruth’s mothers
        were born in Malaysia, so between them there is a full heart for the Gospel to go out in
        Malaysia through NextGen this year.
        <br /> <br />
        Vernon will be preaching on John Chapters 13-17: Jesus’ crucial instructions for the
        disciples on His last night with them in this world – instructions that all who desire to
        follow Him today must know too! */}
      </span>
    ),
  },
];

// ----------------------------------------------------------------------

export default function SpeakerList() {
  return (
    <Box
      sx={{
        my: 1,
        display: 'grid',
        gap: 3,
        gridTemplateColumns: {
          xs: 'repeat(1, 1fr)',
          sm: 'repeat(2, 1fr)',
          md: 'repeat(2, 1fr)',
          lg: 'repeat(2, 1fr)',
        },
      }}
    >
      {CATEGORIES.map((category) => (
        <MotionInView key={category.label} variants={varFade().in}>
          <CategoryCard category={category} />
        </MotionInView>
      ))}
    </Box>
  );
}

// ----------------------------------------------------------------------

type CategoryCardProps = {
  category: {
    label: string;
    icon: string;
    description: any;
    no: string;
  };
};

function CategoryCard({ category }: CategoryCardProps) {
  const { label, icon, description, no } = category;

  return (
    <Paper sx={{ borderRadius: 2, borderColor: 'white', textAlign: 'center', px: 3 }}>
      <Grid container alignItems="center" justifyContent="center">
        <Grid item xs={4} md={6}>
          <Image
            src={icon}
            ratio="3/4"
            sx={{
              borderRadius: 2,
            }}
          />
        </Grid>
      </Grid>

      <Typography variant="subtitle2" sx={{ fontSize: 29, my: 2, fontFamily: FONT_SECONDARY }}>
        {label}
      </Typography>
      <Typography sx={{ mb: 1, fontSize: 19, textAlign: 'left' }}>{description}</Typography>
    </Paper>
  );
}
